import './vendor';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { HelmetProvider } from 'react-helmet-async';
import { action, configure } from 'mobx';
import { App } from './app';
import { ensurePathIsReady } from './util/route/ensure-path-is-ready';
import { RoutesConfig } from './routes';
import { AppStateModel } from './model/app-state.model';
import { getModelSchema } from './util/serializr/get-model-schema';
import { update } from 'serializr';
import {createLogger, LoggerType} from './util/debug/logger.util';
import { createBrowserHistory } from 'history';
import { getActions } from './action/actions';
import { createDispatcher } from './util/action/create-dispatcher.util';
import { createComponentDispatcher } from './util/action/create-component-dispatcher';
import { executePathActions } from './util/route/execute-path-actions';
import { Observable } from 'rxjs/Observable';
import { addGlobalError } from './util/action/type/add-global-error.util';
import { noop } from './util/action/type/noop-action.util';
import { windowResize$ } from './util/dom/window-resize.util';
import { IWindowSize } from './interface/dom/window-size.interface';
import { getTranslation } from './util/translation/get-translation';
import { configureColors } from './enum/color.enum';
import * as smoothscroll from 'smoothscroll-polyfill';
import { ILocation } from './interface/history.interface';
import { IUsedDesignObjectMentionMetadata } from './interface/page/design-object-mention-metadata.interface';
import { findLinkedDataAsyncDataToResolve } from './util/linked-data/find-linked-data-async-data-to-resolve.util';
import * as mobx from 'mobx';
import mobxFormatters from 'mobx-formatters/lib/index';
import StoreProvider from './model/store-provider';

// kick off the polyfill!
smoothscroll.polyfill();

// polyfill string.endsWith for IE11
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(searchString, position) {
    const subjectString = this.toString();
    if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
      position = subjectString.length;
    }
    position -= searchString.length;
    const lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
}

function initializeGlobalErrorHandler$(currentState: AppStateModel, logger: LoggerType) {
  return Observable.fromEvent(window, 'error')
    .map((e: ErrorEvent) => {
      if (!e.error) {
        return noop();
      }
      if (!e.error.name && !e.error.message) {
        console.error(e.error);
        return noop();
      } else {
        logger.error(e.error);
        if (currentState.configuration.debug) {
          return addGlobalError(
            '[CMS debug] - ' + (e.error && e.error.name ? e.error.name : 'Unknown error'),
            e.error && e.error.message ? e.error.message : ''
          );
        } else {
          return noop();
        }
      }
    });
}

configure({
  enforceActions: 'observed'
});

const state = StoreProvider.stores.appState;
update(getModelSchema(AppStateModel), state, (window as any).__STATE__);
configureColors(state.configuration.settings.colors || {} as any);
state.configuration.currentVersion = '2.10.0';
state.configuration.buildDate = 'Tuesday, April 1st, 2025, 7:52:10 PM';

const history = createBrowserHistory();

windowResize$().subscribe(action((windowSize: IWindowSize) => {
  state.windowSize.width = windowSize.width;
  state.windowSize.height = windowSize.height;
}));

if (state.configuration.debug) {
  // add mobx formaters for better source debugging in chrome
  // must turn on "enable custom formaters" under chrome settings
  mobxFormatters (mobx);

  let strictTimeout;
  Object.defineProperty((window as any), 'appState', {
    get: () => {
      if (strictTimeout) {
        clearTimeout(strictTimeout);
      } else {
        configure({
          enforceActions: 'never'
        });
      }
      strictTimeout = setTimeout(() => {
        configure({
          enforceActions: 'observed'
        });
        strictTimeout = undefined;
      }, 100);
      return state;
    }
  });
}

declare global {
  interface Window {
    nonce: string;
  }
}

declare var __webpack_public_path__: string;
declare var __webpack_nonce__: string;

__webpack_nonce__ = window.nonce;

__webpack_public_path__ = state.configuration.staticCDNNUrl + '/';
console.log('static cdn', __webpack_public_path__);

const renderApp = (app, registeredConsole: Console = console) => {
  const logger = createLogger(state, registeredConsole);
  getActions(state.configuration).then(actions => {
    const service = StoreProvider.stores.services(logger);
    const dispatcher = createDispatcher({
      state: state,
      history: history,
      logger: logger,
      getTranslation: getTranslation.bind(getTranslation, state.configuration),
      service
    }, actions);
    const componentDispatcher = createComponentDispatcher(dispatcher, state.configuration);
    history.listen(action((location: ILocation) => {
      state.loadingPath = true;
      executePathActions(RoutesConfig(state.configuration.silentCallbackUrl), dispatcher, location)
        .then(action(() => {
          state.loadingPath = false;
        }));
    }));
    initializeGlobalErrorHandler$(state, logger).subscribe(dispatcher);
    ensurePathIsReady(RoutesConfig(state.configuration.silentCallbackUrl), state.activeDesign.data).then(() => {
      if (state.activeDesign.data && !state.configuration.inAdmin) {
        const mentionsToResolve: IUsedDesignObjectMentionMetadata[] = findLinkedDataAsyncDataToResolve(state.activeDesign.data.linkedData);
        if (mentionsToResolve.length > 0) {
          dispatcher.resolveLinkedData(mentionsToResolve, true, { pagedProperties: { page: 1 } });
        }
      }
      ReactDOM.hydrate(<HelmetProvider>
        <Provider
          state={state}
          dispatch={componentDispatcher}
          history={history}
          logger={logger}
          service={service}
        >
          {app}
        </Provider>
      </HelmetProvider>, document.getElementById('content'));
    });
  });
};

renderApp(<App />);
